import 'magnific-popup';

require( 'owl.carousel' );
import "../../node_modules/jquery.marquee/jquery.marquee";

import nav from './modules/nav';
import landing from './modules/landing';
import memberships from './modules/memberships';
import single_product from './modules/single_product';

$( document ).ready( function ( $ ) {

	nav();

	/*if ( !$( 'body' ).hasClass( 'wp-admin' ) ) {
		landing();
	}*/

	memberships();

	if ( $( 'body' ).hasClass( 'single-product' ) ) {
		single_product();
	}

} );
