export default function nav() {
	
	$('#header .hamburger').on('click', function (e) {
		e.preventDefault();
		$( this ).toggleClass( 'is-active' );
		$( '.header-middle' ).toggleClass( 'active' );
		$('html').toggleClass('mobile-menu-active');

		if (!$(this).hasClass('is-active')) {
			$('#menu-main-menu li .sub-menu').hide();
		}
  });
  
	$('#menu-main-menu li').each(function(){
		if ($(this).find('.sub-menu').length > 0) {
			$(this).append('<i class="far fa-chevron-right"></i>');
		}
	});

	$('#menu-main-menu li i').click(function(e){
		e.preventDefault();

		$(this).toggleClass('fa-chevron-right fa-chevron-down');
		$(this).parent().find('> .sub-menu').slideToggle();
	});
}