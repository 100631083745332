export default function single_product() {
	
	$('.product-detail .add-to-cart .btn').click(function(e){
    e.preventDefault();
    $.ajax({
      url: $(this).attr('href'),
      success: function(res){
				$('.product-detail__title').after($(res).find('.woocommerce-notices-wrapper'));
				$('html, body').animate({
					scrollTop: 0
				}, 800);
      }
    });
  });
  
}