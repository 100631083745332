export default function memberships() {
	
	$('.membership-plans .plan .btn').click(function(e){
    e.preventDefault();
    $.ajax({
      url: $(this).attr('href'),
      success: function(){
        location = '/cart';
      }
    });
  });
  
}